import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getRoadmap } from '../../../queries/getRoadmap.ts';
import { useContext, useEffect, useRef, useState } from 'react';
import Stepper from '@mui/joy/Stepper';
import Step from '@mui/joy/Step';
import { stepClasses, StepIndicator } from '@mui/joy';
import { FormatTime } from '../../../utils.ts';
import { SelectedVehicleContext } from '../../../contexts/SelectedVehicleContext.tsx';
import { useTranslation } from 'react-i18next';
import { Place } from '../../../interfaces/Place.ts';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { Booking } from '../../../interfaces/Booking.ts';
import {
  ACTION_NAME_ABSENT,
  ACTION_NAME_DROP_OFF,
  ACTION_NAME_IN_PLACE,
  ACTION_NAME_PICK_UP,
  ACTION_NAME_READY_TO_LEAVE,
  PLACE_ACTIVITY_PARKING,
} from '../../../constants.ts';
import {
  displayInPlaceButton,
  displayReadyToLeaveButton,
  enableAbsentButton,
  enableDropOffButton,
  enablePickUpButton,
  getBookingsToDropOff,
  getBookingsToPickUp,
  getCurrentPlace,
  getNextPlace,
  getStepIndicatorUi,
  isDropOffButtonChecked,
  isPickUpButtonChecked,
  refreshSelectedPlace,
} from './utils.ts';
import { postActions } from '../../../queries/postActions.ts';
import { toast } from 'react-toastify';
import { ShiftActions } from './ShiftActions.tsx';

interface Props {
  territorySlug: string;
}
export function Roadmap(props: Props) {
  const { selectedVehicle } = useContext(SelectedVehicleContext);
  const ref = useRef<null | HTMLDivElement>(null);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [currentPlace, setCurrentPlace] = useState<undefined | Place>(undefined);
  const [nextPlace, setNextPlace] = useState<undefined | Place>(undefined);
  const [selectedPlace, setSelectedPlace] = useState<null | Place>(null);
  const [isInPlaceButtonLoading, setIsInPlaceButtonLoading] = useState<boolean>(false);
  const [isReadyToLeaveButtonLoading, setIsReadyToLeaveButtonLoading] = useState<boolean>(false);
  const [isPickUpButtonLoading, setIsPickUpButtonLoading] = useState<boolean>(false);
  const [isDropOffButtonLoading, setIsDropOffButtonLoading] = useState<boolean>(false);
  const [showPlaceDetails, setShowPlaceDetails] = useState(false);

  const handleClosePlaceDetails = () => setShowPlaceDetails(false);
  function handleShowPlaceDetails(place: Place) {
    setSelectedPlace(place);
    setShowPlaceDetails(true);
  }
  const {
    data: roadmapData,
    isLoading: dataLoading,
    isError: dataError,
  } = useQuery({
    queryKey: ['roadmap', props.territorySlug, selectedVehicle?.vehicleId],
    queryFn: () =>
      selectedVehicle?.vehicleId ? getRoadmap(props.territorySlug, selectedVehicle?.vehicleId) : null,
    refetchInterval: 10000,
    enabled: selectedVehicle?.vehicleId !== undefined,
    keepPreviousData: true,
    retry: false,
  });

  useEffect(() => {
    if (roadmapData !== undefined) {
      setCurrentPlace(getCurrentPlace(roadmapData?.data));
      setNextPlace(getNextPlace(roadmapData?.data));
      setSelectedPlace(refreshSelectedPlace(selectedPlace, roadmapData?.data));
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'instant', block: 'center' });
      }
    }
  }, [selectedVehicle, roadmapData, ref.current, roadmapData?.data]);

  async function handleInPlaceButtonClick(rideId: number, placeId: number) {
    try {
      setIsInPlaceButtonLoading(true);
      await postActions(props.territorySlug, selectedVehicle?.vehicleId || 0, [
        {
          name: ACTION_NAME_IN_PLACE,
          kwargs: {
            ride_id: rideId,
            place_id: placeId,
          },
        },
      ]);
      await queryClient.invalidateQueries({
        queryKey: ['roadmap', props.territorySlug, selectedVehicle?.vehicleId],
      });
      toast.success(`${t('roadmap.in-place')}`);
    } catch (e) {
      toast.error(`${t('roadmap.in-place-error')}`);
    } finally {
      setIsInPlaceButtonLoading(false);
    }
  }

  async function handleReadyToLeaveButtonClick(rideId: number, placeId: number) {
    try {
      setIsReadyToLeaveButtonLoading(true);
      await postActions(props.territorySlug, selectedVehicle?.vehicleId || 0, [
        {
          name: ACTION_NAME_READY_TO_LEAVE,
          kwargs: {
            ride_id: rideId,
            place_id: placeId,
          },
        },
      ]);
      await queryClient.invalidateQueries({
        queryKey: ['roadmap', props.territorySlug, selectedVehicle?.vehicleId],
      });
      toast.success(`${t('roadmap.ready-to-leave')}`);
      handleClosePlaceDetails();
    } catch (e) {
      toast.error(`${t('roadmap.ready-to-leave-error')}`);
    } finally {
      setIsReadyToLeaveButtonLoading(false);
    }
  }

  async function handlePickUpButtonClick(rideId: number, placeId: number, bookingId: number) {
    setIsPickUpButtonLoading(true);
    try {
      await postActions(props.territorySlug, selectedVehicle?.vehicleId || 0, [
        {
          name: ACTION_NAME_PICK_UP,
          kwargs: {
            ride_id: rideId,
            place_id: placeId,
            booking_id: bookingId,
          },
        },
      ]);
      await queryClient.invalidateQueries({
        queryKey: ['roadmap', props.territorySlug, selectedVehicle?.vehicleId],
      });
      toast.success(`${t('roadmap.pickup')}`);
    } catch (e) {
      toast.error(`${t('roadmap.pickup-error')}`);
    } finally {
      setIsPickUpButtonLoading(false);
    }
  }

  async function handleAbsentButtonClick(rideId: number, placeId: number, bookingId: number) {
    setIsPickUpButtonLoading(true);
    try {
      await postActions(props.territorySlug, selectedVehicle?.vehicleId || 0, [
        {
          name: ACTION_NAME_ABSENT,
          kwargs: {
            ride_id: rideId,
            place_id: placeId,
            booking_id: bookingId,
          },
        },
      ]);
      await queryClient.invalidateQueries({
        queryKey: ['roadmap', props.territorySlug, selectedVehicle?.vehicleId],
      });
      toast.success(`${t('roadmap.absent')}`);
    } catch (e) {
      toast.error(`${t('roadmap.absent-error')}`);
    } finally {
      setIsPickUpButtonLoading(false);
    }
  }
  async function handleDropOffButtonClick(rideId: number, placeId: number, bookingId: number) {
    setIsDropOffButtonLoading(true);
    try {
      await postActions(props.territorySlug, selectedVehicle?.vehicleId || 0, [
        {
          name: ACTION_NAME_DROP_OFF,
          kwargs: {
            ride_id: rideId,
            place_id: placeId,
            booking_id: bookingId,
          },
        },
      ]);
      await queryClient.invalidateQueries({
        queryKey: ['roadmap', props.territorySlug, selectedVehicle?.vehicleId],
      });
      toast.success(`${t('roadmap.dropoff')}`);
    } catch (e) {
      toast.error(`${t('roadmap.dropoff-error')}`);
    } finally {
      setIsDropOffButtonLoading(false);
    }
  }

  return (
    <div className={'top-center-container row m-0 p-0'}>
      <div className={'col my-auto'}>
        <div className={'row'}>
          <ShiftActions territorySlug={props.territorySlug} />
          <div className={'col-9 ps-0'}>
            {dataLoading ? (
              <Spinner className={'ms-4 text-center'} animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : (
              <></>
            )}
            {dataError ? <div className={'text-basic ms-4'}>{t('roadmap.no-roadmap')}</div> : <></>}
            <Stepper
              sx={{
                '--Step-connectorThickness': '12px',
                '--Step-connectorInset': '-2px',
                '--Step-connectorRadius': '0px',
                '--Step-gap': '0px',
                '--StepIndicator-size': '25px',
                '--joy-palette-success-solidBg': 'var(--joy-palette-success-400)',
                [`& .${stepClasses.vertical}`]: {
                  minWidth: '175px',
                  '&::after': {
                    background: '#F2F2F2',
                  },
                },
                [`& .${stepClasses.completed}`]: {
                  '&::after': { bgcolor: '#4385E8' },
                },
              }}>
              {roadmapData?.data && currentPlace !== undefined && nextPlace !== undefined ? (
                roadmapData.data.map((place: Place, i: number) => (
                  <Step
                    id={`roadmap-step-${i}`}
                    sx={{
                      '&::after': {
                        top: 'calc(24px) !important',
                      },
                    }}
                    key={i}
                    completed={i < currentPlace?.order}
                    active={i === currentPlace?.order}
                    disabled={i === roadmapData.data.length - 1}
                    orientation="vertical"
                    indicator={
                      <StepIndicator
                        id={`roadmap-indicator-${i}`}
                        variant="solid"
                        onClick={() => handleShowPlaceDetails(place)}
                        sx={getStepIndicatorUi(i, currentPlace, nextPlace, roadmapData.data)}>
                        {place.type === PLACE_ACTIVITY_PARKING ? (
                          <img
                            src={'/theme/media/parking.svg'}
                            width="12"
                            height="12"
                            alt="Clock icon"
                            style={{ marginLeft: '1px' }}
                          />
                        ) : (
                          <></>
                        )}
                      </StepIndicator>
                    }>
                    <p
                      id={`roadmap-place-${i}`}
                      ref={i === currentPlace?.order + 1 ? ref : null}
                      className={'text-basic text-hover'}
                      onClick={() => handleShowPlaceDetails(place)}>
                      {place.name}
                    </p>
                    <p
                      className={'roadmap-departure-time text-basic'}
                      id={`roadmap-departure-time-${i}`}
                      onClick={() => handleShowPlaceDetails(place)}>
                      {FormatTime(place.departure_time)}
                    </p>
                  </Step>
                ))
              ) : (
                <></>
              )}
            </Stepper>
          </div>
        </div>
      </div>
      <Modal show={showPlaceDetails} onHide={handleClosePlaceDetails} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <p className={'card-subtitle'}>
              {selectedPlace?.name} - #{selectedPlace?.order}
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <p>
                <span className={'text-bold'}>Place : </span>
                <span className={'text-basic'}>{selectedPlace?.id}</span>
              </p>
              <p>
                <span className={'text-bold'}>Type : </span>
                <span className={'text-basic'}>{selectedPlace?.type}</span>
              </p>
              <p>
                <span className={'text-bold'}>Status : </span>
                <span className={'text-basic'}>{selectedPlace?.status}</span>
              </p>
            </Col>
            <Col>
              <p>
                <span className={'text-bold'}>Departure time : </span>
                <span className={'text-basic'}>
                  {selectedPlace ? FormatTime(selectedPlace?.departure_time) : '-'}
                </span>
              </p>
              <p>
                <span className={'text-bold'}>Arrival time : </span>
                <span className={'text-basic'}>
                  {selectedPlace ? FormatTime(selectedPlace?.arrival_time) : '-'}
                </span>
              </p>
              <p>
                <span className={'text-bold'}>Min time : </span>
                <span className={'text-basic'}>
                  {selectedPlace ? FormatTime(selectedPlace?.min_time) : '-'}
                </span>
              </p>
              <p>
                <span className={'text-bold'}>Max time : </span>
                <span className={'text-basic'}>
                  {selectedPlace ? FormatTime(selectedPlace?.max_time) : '-'}
                </span>
              </p>
            </Col>
          </Row>
          {selectedPlace?.bookings.length || 0 > 0 ? (
            <Row>
              <hr className={'basic-hr mt-4 mb-4'} />
              <Col sm={6}>
                <p className={'text-center mb-3'}>
                  <span className={'pickup-badge text-center'}>
                    <img
                      src={`/theme/media/arrow-up.svg`}
                      height="15"
                      className={'me-2'}
                      alt="Pickup or dropoff logo"
                    />
                    Pick-up
                    {isPickUpButtonLoading ? (
                      <Spinner className={'ms-2'} size={'sm'} animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      <></>
                    )}
                  </span>
                </p>
                {getBookingsToPickUp(selectedPlace)?.map((booking: Booking, i: number) => (
                  <div key={i} className={'d-flex align-items-center justify-content-end me-5'}>
                    <p
                      className={`me-3 ${!enablePickUpButton(selectedPlace, currentPlace, booking) ? 'text-disabled' : 'text-basic'}`}>
                      {`${booking.customer_first_name} ${booking.customer_last_name}`}
                    </p>
                    <Form.Check
                      type={'checkbox'}
                      className={'action-checkbox me-3'}
                      checked={isPickUpButtonChecked(booking)}
                      disabled={!enablePickUpButton(selectedPlace, currentPlace, booking)}
                      onClick={() =>
                        handlePickUpButtonClick(
                          selectedVehicle?.shift?.ride || 0,
                          selectedPlace?.id || 0,
                          booking.id,
                        )
                      }
                    />
                    {enableAbsentButton(selectedPlace, currentPlace, booking) ? (
                      <a
                        onClick={() =>
                          handleAbsentButtonClick(
                            selectedVehicle?.shift?.ride || 0,
                            selectedPlace?.id || 0,
                            booking.id,
                          )
                        }>
                        <img
                          src={`/theme/media/absent.svg`}
                          height="25"
                          className={'mt-1'}
                          alt="Absent logo"
                        />
                      </a>
                    ) : (
                      <></>
                    )}
                  </div>
                ))}
              </Col>
              <Col sm={6}>
                <p className={'text-center mb-3'}>
                  <span className={'dropoff-badge text-center'}>
                    <img
                      src={`/theme/media/arrow-down.svg`}
                      height="15"
                      className={'me-2'}
                      alt="Pickup or dropoff logo"
                    />
                    Drop-Off
                    {isDropOffButtonLoading ? (
                      <Spinner className={'ms-2'} size={'sm'} animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      <></>
                    )}
                  </span>
                </p>
                {getBookingsToDropOff(selectedPlace)?.map((booking: Booking, i: number) => (
                  <div key={i} className={'d-flex align-items-center justify-content-start ms-5'}>
                    <Form.Check // prettier-ignore
                      type={'checkbox'}
                      className={'action-checkbox'}
                      checked={isDropOffButtonChecked(booking)}
                      disabled={!enableDropOffButton(selectedPlace, currentPlace, booking)}
                      onClick={() =>
                        handleDropOffButtonClick(
                          selectedVehicle?.shift?.ride || 0,
                          selectedPlace?.id || 0,
                          booking.id,
                        )
                      }
                    />
                    <p
                      className={`ms-2 ${!enableDropOffButton(selectedPlace, currentPlace, booking) ? 'text-disabled' : 'text-basic'}`}>
                      {`${booking.customer_first_name} ${booking.customer_last_name}`}
                    </p>
                  </div>
                ))}
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row className={'mt-3'}>
            <Col>
              <Row className={'mt-5'}>
                {selectedVehicle?.shift?.ride &&
                selectedPlace?.id &&
                currentPlace !== undefined &&
                nextPlace !== undefined ? (
                  <>
                    <Col sm={6} className={'text-sm-end'}>
                      <Button
                        disabled={
                          !displayInPlaceButton(
                            currentPlace,
                            nextPlace,
                            selectedPlace,
                            selectedVehicle?.shift,
                          )
                        }
                        onClick={() =>
                          handleInPlaceButtonClick(selectedVehicle?.shift?.ride, selectedPlace?.id)
                        }
                        className={'submit-button'}>
                        {isInPlaceButtonLoading ? (
                          <span className={'d-flex justify-content-center'}>
                            <Spinner size={'sm'} animation="border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            <span className={'ms-2'}>{t('roadmap.in-place-button')}</span>
                          </span>
                        ) : (
                          <span>{t('roadmap.in-place-button')}</span>
                        )}
                      </Button>
                    </Col>
                    <Col sm={6}>
                      <Button
                        disabled={!displayReadyToLeaveButton(currentPlace, selectedPlace)}
                        onClick={() =>
                          handleReadyToLeaveButtonClick(selectedVehicle?.shift?.ride, selectedPlace?.id)
                        }
                        className={'submit-button'}>
                        {isReadyToLeaveButtonLoading ? (
                          <span className={'d-flex justify-content-center'}>
                            <Spinner size={'sm'} animation="border" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </Spinner>
                            <span className={'ms-2'}>{t('roadmap.ready-to-leave-button')}</span>
                          </span>
                        ) : (
                          <span>{t('roadmap.ready-to-leave-button')}</span>
                        )}
                      </Button>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  );
}
